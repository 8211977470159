import axios from 'axios';
import * as types from 'types/media';
import * as helpers from './helpers';

const createRequest = () => ({
  type: types.CREATE_REQUEST,
});

const createSuccess = ({ media }) => ({
  type: types.CREATE_SUCCESS,
  payload: {
    media,
  },
});

export const saveMIDI = ({ progressionParts }) => async dispatch => {
  if (!progressionParts) return null;
  dispatch(createRequest());
  try {
    const {
      data: {
        data: { media },
      },
    } = await axios({
      method: 'post',
      url: '/v1/media/midi/upload',
      data: {
        progressionParts,
      },
    });
    return dispatch(createSuccess({ media }));
  } catch (errorResponse) {
    return dispatch(helpers.errorAction(types.CREATE_FAILURE, errorResponse));
  }
};

/**
 * List/Search
 */
const searchRequest = ({ append }) => ({
  type: types.SEARCH_REQUEST,
  payload: {
    append,
  },
});

const searchSuccess = ({ mediaList, append, pagination, links }) => ({
  type: types.SEARCH_SUCCESS,
  payload: {
    append,
    mediaList,
    pagination,
    links,
  },
});

export const searchMidi = ({ query, append = false }) => async dispatch => {
  dispatch(searchRequest({ append }));
  try {
    const {
      data: { data, meta, links },
    } = await axios.get(`/v1/media/midi`, {
      params: query,
    });
    return dispatch(
      searchSuccess({
        mediaList: data,
        pagination: meta,
        append,
        links,
      }),
    );
  } catch (errorResponse) {
    return dispatch(helpers.errorAction(types.SEARCH_FAILURE, errorResponse));
  }
};
