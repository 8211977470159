import { useState } from 'react';

const bpmState = {
  count: 0,
  firstTouchTime: 0,
  prevTouchTime: 0,
  waitTime: 2,
  bpmAvg: 0,
};

// tempo based on average of taps on not slower than 2 seconds
// function oldtapEventHandler(e) {
//   const timeSeconds = new Date;
//   const currentTouchTime = timeSeconds.getTime();
//   // reset tap counter after wait milliseconds
//   if ((currentTouchTime - bpmState.prevTouchTime) > 1000 * bpmState.waitTime) {
//     bpmState.count = 0;
//     bpmState.bpmAvg = 0;
//   }
//
//   if (bpmState.count == 0) {
//     bpmState.firstTouchTime = currentTouchTime;
//     bpmState.count = 1;
//   } else {
//     const bpmAvg = (60000 * bpmState.count) / (currentTouchTime - bpmState.firstTouchTime);
//     bpmState.bpmAvg = bpmAvg;
//     bpmState.count += 1;
//   }
//   bpmState.prevTouchTime = currentTouchTime;
// }

// tempo based on average tap time of last 4 taps
function useBpmAverage(defaultValue) {
  const [bpmAvgState, setBpm] = useState(defaultValue);

  const setBpmTap = () => {
    const timeSeconds = new Date();
    const currentTouchTime = timeSeconds.getTime();
    // reset tap counter after wait milliseconds
    if (bpmState.count > 4) {
      bpmState.count = 0;
    }
    if (bpmState.count === 0) {
      bpmState.firstTouchTime = currentTouchTime;
      bpmState.count = 1;
    } else {
      const bpmAvg =
        (60000 * bpmState.count) / (currentTouchTime - bpmState.firstTouchTime);
      setBpm(Math.round(bpmAvg));
      bpmState.count += 1;
    }
    bpmState.prevTouchTime = currentTouchTime;
    return bpmAvgState;
  };
  return [bpmAvgState, setBpmTap];
}

export default useBpmAverage;
