import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useTheme from 'selectors/use-theme';
import Button from 'components/Button';
import styles from './BPMInput.css';
import useBpmAverage from './use-bpm-average';

export default function BPMInput({
  name,
  label,
  value,
  onChange,
  hideTapButton,
  ...rest
}) {
  useStyles(styles);
  const [currentValue, setCurrentValue] = useState('');
  const theme = useTheme();
  const [bpmAvg, setBpmAvg] = useBpmAverage(0);

  useEffect(
    function setCurrentValueFromProps() {
      if (value) setCurrentValue(value);
    },
    [value],
  );

  useEffect(
    function callOnChangeHandler() {
      onChange(Number(currentValue));
    },
    [currentValue],
  );

  useEffect(
    function setCurrentValueFromAverage() {
      if (bpmAvg) {
        setCurrentValue(bpmAvg);
      }
    },
    [bpmAvg],
  );

  const onChangeHandler = e => {
    setCurrentValue(e.target.value);
  };

  const onTapHandler = () => {
    setBpmAvg();
  };
  /* eslint css-modules/no-unused-class: off */
  return (
    <div
      className={[
        styles.bpmInputContainer,
        styles[`${theme}BpmInputContainer`],
      ].join(' ')}
      htmlFor={name}
    >
      <span className={styles.label}>{label}</span>
      <span className={!hideTapButton ? styles.btnInputGroupContainer : ''}>
        <input
          type="number"
          id={name}
          name={name}
          min={1}
          max={Infinity}
          value={currentValue}
          onChange={onChangeHandler}
          {...rest}
          className={styles.numberInput}
        />
        {!hideTapButton && (
          <span className={styles.tapBtnContainer}>
            <Button onClick={onTapHandler} title="Increase">
              Tap
            </Button>
          </span>
        )}
      </span>
    </div>
  );
}

BPMInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.number,
  hideTapButton: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

BPMInput.defaultProps = {
  hideTapButton: false,
  value: 0,
};
