// import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ChordEditor from 'components/ChordEditor';
import Settings from 'components/Settings';
import ProgressionInfo from 'components/ProgressionInfo';
import ErrorBoundary from 'components/ErrorBoundary';
import PlayStopButton from 'components/PlayStopButton';
import Message from 'components/Message';
import { BlockSpace } from 'components/Common/Spacing';
import Portal from 'components/Portal';

import { resetCurrentProgression } from 'actions/progression';
import { resetProgressionSettings } from 'actions/progression-settings';
import { clearPlayer } from 'actions/player';

// import styles from './Main.css';

export default function Main() {
  // useStyles(styles);
  const dispatch = useDispatch();
  const { error } = useSelector(state => state.progression);
  const playerState = useSelector(state => state.player);
  const { error: parseError } = useSelector(state => state.progressionSettings);

  useEffect(() => {
    return function cleanup() {
      // eslint-disable-next-line no-unused-expressions
      playerState.progressionPlayer?.clear();
      dispatch(resetCurrentProgression());
      dispatch(clearPlayer());
      dispatch(resetProgressionSettings());
    };
  }, []);

  return (
    <div>
      {!parseError && error && (
        <Portal>
          <Message messageType="error" isNotification>
            {error}
          </Message>
        </Portal>
      )}
      {parseError && (
        <Portal>
          <Message messageType="error" isNotification>
            {parseError}
          </Message>
        </Portal>
      )}
      <ErrorBoundary>
        <ProgressionInfo />
        <BlockSpace size={25} />
      </ErrorBoundary>
      <ErrorBoundary>
        <ChordEditor />
      </ErrorBoundary>
      <BlockSpace size={50} />
      <ErrorBoundary>
        <Settings />
      </ErrorBoundary>
      <BlockSpace size={50} />
      <ErrorBoundary>
        <PlayStopButton />
      </ErrorBoundary>
    </div>
  );
}
