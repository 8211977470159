import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import EmptyMessage from 'components/Common/EmptyMessage';
import { Text } from 'components/Common/Typography';
import { PLACEHOLDER } from 'design-system/inventory/heirarchy';

import { setSyncChanges } from 'actions/player';

import { classNamesHelper } from 'utils';

import styles from './ChordDetailPlayer.css';

// size in pixels
// chord-display padding left is 20px
const INTIAL_MARKER_POSITION = 20;

function qualityToShortName(quality) {
  const maps = {
    major: 'maj',
    minor: 'min',
    diminished: 'dim',
    augmented: 'aug',
    sus: 'sus',
  };
  return maps[quality];
}

/**
 * @param {HTMLElement} el
 * @return {{top: number, left: number}}
 */
function getDocumentOffsetPosition(el) {
  const position = {
    top: el.offsetTop,
    left: el.offsetLeft,
  };
  if (el.offsetParent) {
    const parentPosition = getDocumentOffsetPosition(el.offsetParent);
    position.top += parentPosition.top;
    position.left += parentPosition.left;
  }
  return position;
}

function formatTime(seconds) {
  const format = val => `0${Math.floor(val)}`.slice(-2);
  const minutes = (seconds % 3600) / 60;
  const milliseconds = seconds * 1000;
  const timeArray = [minutes, seconds % 60].map(format);
  timeArray.push(Math.round(milliseconds % 1000, 2));
  return timeArray.join(':');
}

export default function ChordDetailPlayer() {
  useStyles(styles);
  const dispatch = useDispatch();

  const progressionSettings = useSelector(state => state.progressionSettings);
  const playerState = useSelector(state => state.player);
  const appState = useSelector(state => state.app);

  function getPlayerContainer() {
    return document.getElementById('playerContainer');
  }

  function getPlayerContainerBoundingRect() {
    return getPlayerContainer().getBoundingClientRect();
  }

  function isInViewport(elem) {
    const bounding = elem.getBoundingClientRect();
    const playerWidth = getPlayerContainer().offsetWidth;
    const playerMarginOffset = getPlayerContainerBoundingRect().left;
    return bounding.right + bounding.width <= playerWidth + playerMarginOffset;
  }

  function resetScroll() {
    const playerContainer = getPlayerContainer();
    playerContainer.scrollLeft = 1;
  }

  function updateMarker(data) {
    const chordDiv = document.getElementById(`chord-${data.index}`);
    const prevChordDiv = document.getElementById(`chord-${data.index - 1}`);
    // const chordWidth = chordDiv.offsetWidth + CHORD_SEPARATOR;

    // const chordPosition = getDocumentOffsetPosition(chordDiv);
    const prevChordPosition = getDocumentOffsetPosition(prevChordDiv);
    const playerContainer = getPlayerContainer();
    const stateChanges = {};
    if (!isInViewport(chordDiv)) {
      playerContainer.scrollLeft =
        prevChordPosition.left - getPlayerContainerBoundingRect().left;
      stateChanges.scrollLeft =
        prevChordPosition.left - getPlayerContainerBoundingRect().left;
    }

    dispatch(setSyncChanges(stateChanges));
  }

  function stopProgression() {
    playerState.progressionPlayer.stopProgression();
    dispatch(
      setSyncChanges({
        isPlaying: false,
        completed: true,
        loopCount: 0,
      }),
    );
  }

  function checkCompleted({ chordOrNoteNumber }) {
    if (chordOrNoteNumber === playerState.totalChords - 1) {
      if (progressionSettings.loop === false) {
        stopProgression();
        // setLastTime(data);
        return;
      }
      dispatch(
        setSyncChanges({
          loopCount: playerState.loopCount + 1,
          completed: true,
        }),
      );
    }
  }

  function handleFirstChord() {
    resetScroll();
    dispatch(
      setSyncChanges({
        markerPosition: INTIAL_MARKER_POSITION,
      }),
    );
  }

  function handleChordPlay(data) {
    dispatch(
      setSyncChanges({
        currentTime: data.time,
        prevIndex: data.index,
      }),
    );
    if (
      progressionSettings.arpeggiateStyle ||
      progressionSettings.customArpeggio
    ) {
      // playerOptions.progress = data.noteNumber;
      if (playerState.prevIndex !== data.index && data.index !== 0) {
        updateMarker(data);
      } else if (playerState.prevIndex !== data.index) {
        handleFirstChord();
      }
      dispatch(
        setSyncChanges({
          progress: data.index,
        }),
      );

      checkCompleted({ data, chordOrNoteNumber: data.noteNumber });
      return;
    }

    dispatch(
      setSyncChanges({
        progress: data.index,
      }),
    );

    if (data.index !== 0) {
      updateMarker(data);
    } else {
      handleFirstChord();
    }
    // activateCurrentChord({ progressionSettings, data});
    checkCompleted({ data, chordOrNoteNumber: data.index });
  }
  // chordPlay event handler should only be attached once
  // should change when playerState changes
  useEffect(() => {
    playerState.progressionPlayer.on('play:progression', handleChordPlay);
    return () => {
      playerState.progressionPlayer.removeListener(
        'play:progression',
        handleChordPlay,
      );
    };
  }, [playerState]);

  // async function playProgression() {
  //   await Tone.context.resume();
  //   if (playerState.completed) {
  //     resetScroll();
  //   }
  //   playerState.progressionPlayer.playProgression();
  //   dispatch(
  //     setSyncChanges({
  //       isPlaying: true,
  //       progress: 0,
  //       completed: false,
  //       loopCount: 0,
  //       markerPosition: INTIAL_MARKER_POSITION,
  //       scrollLeft: 1,
  //     }),
  //   );
  // }

  const isArpeggio = () => {
    return (
      progressionSettings.arpeggiateStyle || progressionSettings.customArpeggio
    );
  };

  const { progressionParts } = playerState.progressionPlayer;
  const endTime = progressionParts.length
    ? Math.round(progressionParts[progressionParts.length - 1].time, 2)
    : 0;

  const lastNoteLength = progressionParts.length
    ? Math.round(progressionParts[progressionParts.length - 1].noteLength, 2)
    : 0;

  function getNoteKey(noteOctave, index) {
    return `${noteOctave.note}-${index}`;
  }
  function getChordKey(chordObject, index) {
    return `${chordObject.quality}-${index}`;
  }

  function chordsMap() {
    const { parsedProgression } = progressionSettings;
    let progressionPartIndex =
      progressionParts.length &&
      progressionParts[0].chord &&
      0 - progressionParts[0].chord.length;
    return parsedProgression?.map((chordObject, index) => {
      let chordText = '';
      if (chordObject.rest) {
        progressionPartIndex += 1;
        chordText = <span className={styles.rest}>Rest</span>;
      } else {
        progressionPartIndex += chordObject.chord.length;
        chordText = chordObject.chord.map((noteOctave, noteIndex) => {
          return (
            <span key={`chord-note-${getNoteKey(noteOctave, noteIndex)}`}>
              <span className={styles.note}>{noteOctave.note}</span>
              <sub className={styles.octave}>{noteOctave.octave}</sub>
              <small className={styles['chord-note-separator']}>
                {/* chordObject.chord.length - 1 !== noteIndex && '.' */}
              </small>
            </span>
          );
        });
      }
      return (
        <div key={`chord-player-chord-${getChordKey(chordObject, index)}`}>
          <div id={`chord-${index}`} className={[styles.chord].join(' ')}>
            <div
              className={[
                styles['chord-notes'],
                styles[`quality-${chordObject.quality}`],
                playerState.prevIndex === index ? styles['active-chord'] : '',
              ].join(' ')}
            >
              <span>{chordText}</span>
            </div>
            <div className={styles['chord-info']}>
              <div className={styles['chord-number']}>#{index + 1}</div>
              <div
                className={[
                  styles['quality-label'],
                  styles[`quality-label-${chordObject.quality}`],
                ].join(' ')}
              >
                {chordObject.quality
                  ? qualityToShortName(chordObject.quality)
                  : ''}
              </div>
              <div
                className={[
                  styles.inversion,
                  styles[`inversion-${chordObject.inversion}`],
                ].join(' ')}
              >
                {!chordObject.rest ? chordObject.inversion : ''}
              </div>
            </div>
          </div>
          <div className={styles['chord-time']}>
            {!isArpeggio() &&
              progressionParts[index] &&
              formatTime(progressionParts[index].time)}
            {/* If chord is played in arpeggio, show start time of start note of a chord */
            isArpeggio() &&
              progressionParts[progressionPartIndex] &&
              progressionParts[progressionPartIndex].noteIndex === 0 &&
              formatTime(
                Math.round(progressionParts[progressionPartIndex].time, 2),
              )}
          </div>
        </div>
      );
    });
  }

  /* eslint css-modules/no-unused-class: off */
  return (
    <div
      className={classNamesHelper([styles.container, styles[appState.theme]])}
    >
      <div
        className={styles['player-container']}
        style={{ scrollLeft: playerState.scrollLeft }}
        id="playerContainer"
      >
        <div className={styles['chord-display']} id="chordDisplay">
          {chordsMap()}
          {!progressionSettings.parsedProgression?.length && (
            <EmptyMessage>Chord Details Here.</EmptyMessage>
          )}
          <div className="chord-time">
            {/* formatTime(chordTimings[index].time) */}
          </div>
        </div>
        {/* <div
          className={styles['playing-marker']}
          style={{ left: `${playerState.markerPosition}px` }}
          id="playingMarker"
        /> */}
      </div>
      <div className={styles['chord-start-end-time']} id="chordStartEndTime">
        <Text heirarchy={PLACEHOLDER}>
          {formatTime(playerState.currentTime)}
        </Text>
        <Text heirarchy={PLACEHOLDER}>&nbsp;-&nbsp;</Text>
        <Text heirarchy={PLACEHOLDER}>
          {formatTime(endTime + lastNoteLength)}
        </Text>
      </div>
    </div>
  );
}
