// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root{--label-font-size:14px;--test-color:#fff}._2Hgo0{width:100%;color:var(--font-color-placeholder);text-align:center;font-weight:500;font-size:var(--size-100)}._2f4dg{color:var(--font-color-placeholder-dark)}", ""]);
// Exports
exports.locals = {
	"container": "_2Hgo0",
	"dark-mode": "_2f4dg"
};
module.exports = exports;
