import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import PropTypes from 'prop-types';

import { CenterBoth } from 'components/Common/Layout';

import { classNamesHelper } from 'utils';

import useTheme from 'selectors/use-theme';
import styles from './EmptyMessage.css';

export default function EmptyMessage({ children }) {
  useStyles(styles);
  const appTheme = useTheme();
  /* eslint css-modules/no-unused-class: off */
  return (
    <CenterBoth>
      <div className={classNamesHelper([styles.container, styles[appTheme]])}>
        {children}
      </div>
    </CenterBoth>
  );
}

EmptyMessage.propTypes = {
  children: PropTypes.node.isRequired,
};
