import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import useTheme from 'selectors/use-theme';

import { setSyncChanges, parseProgression } from 'actions/progression-settings';
import { saveMIDI } from 'actions/media';
import { initProgression } from 'actions/player';
import { classNamesHelper } from 'utils';
import { useUrlParamContext } from 'contexts/UrlParamContext';
import { STATUS_SUCCESS } from 'types/app';

import Button from 'components/Button';
// import ChordVisualizer from 'components/ChordVisualizer';
import ChordDetailPlayer from 'components/ChordDetailPlayer';
import { BlockSpace } from 'components/Common/Spacing';
import { TertiaryHierarchyText } from 'components/Common/Typography';

import styles from './ChordEditor.css';

const chords = ['C', 'D', 'E', 'F', 'G', 'A', 'B'];
const chordSymbols = [
  '#',
  'b',
  'M',
  'm',
  'sus',
  'aug',
  '+',
  'dim',
  '-',
  '2',
  '4',
  '6',
  '7',
  '9',
  '11',
];

const buttonNotes = [
  // { value: 'x', label: 'X' },
  // { value: '<-', label: '<-' },
  // { value: 'MIDI', label: 'MIDI' },
  { value: 'C', label: 'C' },
  { value: 'D', label: 'D' },
  { value: 'E', label: 'E' },
  { value: 'F', label: 'F' },
  { value: 'G', label: 'G' },
  { value: 'A', label: 'A' },
  { value: 'B', label: 'B' },
  { value: '#', label: '#' },
  { value: 'b', label: 'b' },
  { value: 'M', label: 'M' },
  { value: 'm', label: 'm' },
  { value: 'sus', label: 'sus' },
  { value: 'aug', label: 'aug' },
  { value: 'dim', label: 'dim' },
  { value: '2', label: '2' },
  { value: '4', label: '4' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '9', label: '9' },
  // { value: '11', label: '11' },
];

const DEL_CHAR = 'x';
const BACKSPACE_CHAR = '<-';

export default function ChordEditor() {
  useStyles(styles);
  const chordTextClassNames = [styles.chordtext];
  const theme = useTheme();
  const textAreaRef = useRef();
  const params = useUrlParamContext();
  const progressionSettings = useSelector(state => state.progressionSettings);
  const { currentProgression } = useSelector(state => state.progression);
  const { progressionPlayer, samplerConfig } = useSelector(
    state => state.player,
  );
  const dispatch = useDispatch();
  const [selectionPositions, setSelectionPositions] = useState(null);
  const [textValue, setTextValue] = useState('');

  if (progressionSettings && progressionSettings.warnEmpty) {
    chordTextClassNames.push(styles['empty-input']);
  }

  const clearWarningCallback = () => {
    dispatch(
      setSyncChanges({
        warnEmpty: false,
      }),
    );
  };

  useEffect(
    function setTextAreaFromCurrentProgression() {
      if (params?.progressionSlug && currentProgression?.progressionText) {
        setTextValue(currentProgression.progressionText);
      }
    },
    [params, currentProgression],
  );

  useEffect(
    function setTextAreaOnTranspose() {
      if (progressionSettings.transposeStatus === STATUS_SUCCESS) {
        dispatch(
          setSyncChanges({
            autoChanged: true,
          }),
        );
        setTextValue(progressionSettings.progressionText);
      }
    },
    [progressionSettings.transposeStatus, progressionSettings.progressionText],
  );

  // clear empty-input className after certain milliseconds
  useEffect(() => {
    const timer = setTimeout(clearWarningCallback, 100);
    return () => clearTimeout(timer);
  }, [progressionSettings.warnEmpty]);

  useEffect(
    function setProgressionTextState() {
      const timer = setTimeout(() => {
        const newState = {
          progressionText: textValue,
        };
        // if (!progressionSettings.autoChanged) {
        //   newState.originalProgressionText = textValue;
        // }
        dispatch(setSyncChanges(newState));
      }, 300);
      return () => clearTimeout(timer);
    },
    [textValue],
  );

  useEffect(
    function setSelectionPositionsTextArea() {
      if (textValue && textAreaRef.current && selectionPositions) {
        textAreaRef.current.selectionStart = selectionPositions?.startPosition;
        textAreaRef.current.selectionEnd = selectionPositions?.endPosition;
        textAreaRef.current.focus();
      }
    },
    [textValue],
  );

  // parse progression after typing complete
  useEffect(() => {
    if (!progressionSettings.progressionText) {
      setTextValue('');
      dispatch(
        setSyncChanges({
          parsedLines: [],
          parsedProgression: [],
        }),
      );
      return;
    }
    dispatch(parseProgression());
    dispatch(initProgression());
  }, [
    // changes to these value causes the effect to re-execute
    progressionSettings.progressionText,
    progressionSettings.arpeggiateStyle,
    progressionSettings.customArpeggio,
    progressionSettings.octave,
    progressionSettings.rhythm,
    // tempo tap causes frequent server requests
    progressionSettings.bpm,
  ]);

  // re-build tonejs progression settings, when settings changes
  useEffect(() => {
    if (progressionSettings.parsedProgression) {
      dispatch(initProgression());
    }
  }, [
    progressionSettings.bpm,
    progressionSettings.loop,
    progressionSettings.time,
    progressionSettings.velocity,
    progressionSettings.ADSR,
    progressionSettings.IADSR,
    progressionSettings.instrument,
    progressionSettings.timeSignature,
    progressionSettings.rhythm,
    samplerConfig,
  ]);

  const onChangeHandler = e => {
    const { value } = e.target;
    setTextValue(value);
    setSelectionPositions(null);
    dispatch(
      setSyncChanges({
        originalProgressionText: value,
        autoChanged: false,
        transposeStatus: false,
      }),
    );
  };

  const getPreviousCharacter = selectionStart => {
    return progressionSettings.progressionText?.slice(
      selectionStart - 1,
      selectionStart,
    );
  };

  const getNoteToAppend = (note, selectionStart) => {
    const previousCharacter = getPreviousCharacter(selectionStart);
    if (!previousCharacter) return note;

    if (/\s/.test(previousCharacter)) {
      return note;
    }

    /* if last text is note */
    if (chords.includes(previousCharacter)) {
      if (chords.includes(note)) {
        return ` ${note}`;
      }
      return note;
    }

    if (chordSymbols.concat(['s', 'g']).includes(previousCharacter)) {
      if (chordSymbols.includes(note)) {
        return `${note}`;
      }
      return ` ${note}`;
    }
    return ` ${note}`;
  };

  const handleEditorButton = buttonValue => () => {
    const { selectionStart, selectionEnd } = textAreaRef.current;
    if (buttonValue === DEL_CHAR) {
      setTextValue('');
      return;
    }
    if (buttonValue === BACKSPACE_CHAR) {
      setTextValue(textValue.slice(0, -1));
      return;
    }
    if (buttonValue === 'MIDI') {
      dispatch(
        saveMIDI({
          progressionParts: progressionPlayer?.progressionParts,
        }),
      );
      return;
    }
    const frontText = textValue.substring(0, selectionStart);
    const backText = textValue.substring(selectionEnd, textValue.length);
    const textToAppend = getNoteToAppend(buttonValue, selectionStart);
    const newText = frontText + textToAppend + backText;
    setTextValue(newText);
    setSelectionPositions({
      startPosition: selectionStart + textToAppend.length,
      endPosition: selectionStart + textToAppend.length,
    });
  };

  /* eslint css-modules/no-unused-class: off */
  return (
    <div
      className={classNamesHelper([
        styles['progression-container'],
        styles[theme],
      ])}
    >
      <div className={styles.toolBar}>
        {buttonNotes.map(buttonNote => {
          return (
            <span key={`editor-btn-${buttonNote.value}`}>
              <Button
                buttonType="default"
                onClick={handleEditorButton(buttonNote.value)}
              >
                {buttonNote.label}
              </Button>
            </span>
          );
        })}
      </div>
      <div className={classNamesHelper([styles.splitContainer])}>
        <textarea
          ref={textAreaRef}
          className={chordTextClassNames.join(' ')}
          id="progressionText"
          name="progressionText"
          placeholder="Enter chords. E.g C G Am F"
          value={textValue}
          onChange={onChangeHandler}
        />
        {/* <div className={styles.previewContainer}> */}
        {/*  <ChordVisualizer editorView /> */}
        {/* </div> */}
      </div>
      <div className={styles.footer}>
        <TertiaryHierarchyText>
          <small>Chords should be in capital letters e.g G D F# Bb</small>
        </TertiaryHierarchyText>
      </div>
      <BlockSpace size={25} />
      <ChordDetailPlayer />
      <BlockSpace size={25} />
    </div>
  );
}
