import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useTheme from 'selectors/use-theme';
import styles from './Collapsible.css';

export default function Collapsible({ title, children }) {
  useStyles(styles);
  const theme = useTheme();
  const [showContent, setContentState] = useState(false);

  const toggleCollapse = () => {
    setContentState(!showContent);
  };

  /* eslint css-modules/no-unused-class: off */
  return (
    <div className={[styles[theme]]}>
      <button
        type="button"
        className={[styles.collapsible, showContent && styles.active].join(' ')}
        onClick={toggleCollapse}
      >
        {title || 'Open'}
        {showContent ? <span>&#9661;</span> : <span>&#9655;</span>}
      </button>
      <div
        className={[
          styles.content,
          showContent ? styles.show : styles.hide,
        ].join(' ')}
      >
        {children}
      </div>
    </div>
  );
}

Collapsible.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
