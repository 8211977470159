// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".iYfdn{-ms-flex-direction:column-reverse;flex-direction:column-reverse;position:relative;-ms-flex-align:center;align-items:center;-ms-flex-pack:start;justify-content:flex-start}.iYfdn,._3UQ9I{display:-ms-flexbox;display:flex}._3UQ9I button{padding-bottom:9px}._2UdM7{width:70px}._3UQ9I ._2UdM7{border-top-right-radius:0;border-bottom-right-radius:0;border-right:none}._28roA button{border-top-left-radius:0;border-bottom-left-radius:0}._2zLkR{color:var(--font-color-placeholder);font-size:var(--label-font-size)}@media (max-width:768px){._2UdM7{width:var(--size-static-400)}.iYfdn button{padding-bottom:11px}}", ""]);
// Exports
exports.locals = {
	"bpmInputContainer": "iYfdn",
	"btnInputGroupContainer": "_3UQ9I",
	"numberInput": "_2UdM7",
	"tapBtnContainer": "_28roA",
	"label": "_2zLkR"
};
module.exports = exports;
