import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import nprogress from 'nprogress';

import SampleLibrary from 'lib/tonejs-instruments';

import useTheme from 'selectors/use-theme';
import usePlayer from 'hooks/use-player';

import {
  transposeProgression,
  setInstrumentRequest,
  setInstrumentSuccess,
  setSyncChanges,
} from 'actions/progression-settings';
import { setSyncChanges as setPlayerSyncChanges } from 'actions/player';

import CustomCheckBox from 'components/CustomCheckBox';
import { BlockSpace, InlineSpace } from 'components/Common/Spacing';
import Collapsible from 'components/Collapsible';
import NumberRange from 'components/NumberRange';
import BPMInput from 'components/BPMInput';

import { classNamesHelper, debounceInput } from 'utils';

import styles from './Settings.css';

const getFormValue = e => {
  if (e.target.type === 'checkbox') return e.target.checked;
  const value =
    e.target.type === 'number' ? Number(e.target.value) : e.target.value;
  return value;
};

export function useFormInput(initialValue) {
  const [value, setValue] = useState(initialValue);

  const onChange = e => {
    setValue(getFormValue(e));
  };

  return {
    value,
    onChange,
    setValue,
  };
}

export function getInputProps(formInputObject) {
  const newProps = { ...formInputObject };
  delete newProps.setValue;
  return newProps;
}

export default function Settings() {
  useStyles(styles);
  const dispatch = useDispatch();
  const theme = useTheme();
  const progressionSettings = useSelector(state => state.progressionSettings);
  const { playerState } = usePlayer();

  const transposeInput = useFormInput(progressionSettings.transpose);
  const ADSR = useFormInput('');

  const transposeHandler = debounceInput(async transposeValue => {
    if (!progressionSettings.progressionText) {
      return;
    }
    let originalProgression = progressionSettings.originalProgressionText;
    if (!progressionSettings.originalProgressionText) {
      originalProgression = progressionSettings.progressionText;
      dispatch(
        setSyncChanges({
          originalProgressionText: progressionSettings.progressionText,
        }),
      );
    }
    const transposeNum = Number(transposeValue);
    dispatch(
      transposeProgression({
        progressionText: originalProgression,
        transpose: transposeNum,
      }),
    );
  });

  const formInputOnChangeHandler = fieldName => async e => {
    return dispatch(
      setSyncChanges({
        [fieldName]: getFormValue(e),
      }),
    );
  };

  const onBPMChange = debounceInput(bpm => {
    if (!bpm) return;
    dispatch(
      setSyncChanges({
        bpm,
      }),
    );
  });

  const instrumentChangeHandler = async (e, instrument) => {
    let samplerConfig = null;
    const instrumentValue = e ? getFormValue(e) : instrument;
    if (instrumentValue === 'web-audio') {
      dispatch(setInstrumentSuccess(instrumentValue));
      playerState.progressionPlayer.clearSampler();
      dispatch(
        setPlayerSyncChanges({
          samplerConfig,
        }),
      );
      return;
    }

    nprogress.start();
    dispatch(setInstrumentRequest());
    const baseUrl = window.App.assetsPath
      ? `${window.App.assetsPath}/samples/`
      : '/samples/';

    samplerConfig = SampleLibrary.load({
      ext: '.mp3',
      instruments: instrumentValue,
      baseUrl,
      onload: () => {
        nprogress.done();
        dispatch(setInstrumentSuccess(instrumentValue));
      },
    });
    playerState.progressionPlayer.setSampler(samplerConfig);
    dispatch(
      setPlayerSyncChanges({
        samplerConfig,
      }),
    );
  };

  useEffect(() => {
    if (progressionSettings.instrument) {
      instrumentChangeHandler(null, progressionSettings.instrument);
    }
  }, [progressionSettings.instrument]);

  useEffect(() => {
    transposeInput.setValue(progressionSettings.transpose);
  }, [progressionSettings.transpose]);

  useEffect(
    function restartPlayingWhenSettingsChanges() {
      // auto replay when progression is playing
      // if (playerState.isPlaying && progressionSettings.loop) {
      //   restart();
      // }
    },
    [
      progressionSettings.loop,
      progressionSettings.transpose,
      progressionSettings.octave,
      progressionSettings.time,
      progressionSettings.bpm,
      // progressionSettings.instrument,
      progressionSettings.arpeggiateStyle,
      progressionSettings.velocity,
      progressionSettings.ADSR,
      progressionSettings.IADSR,
      progressionSettings.timeSignature,
      progressionSettings.rhythm,
      progressionSettings.customArpeggio,
    ],
  );
  /* eslint css-modules/no-unused-class: off */
  return (
    <div>
      <form
        name="progressionControlsForm"
        className={[styles[theme]].join(' ')}
      >
        <div className={styles['form-section']}>
          <CustomCheckBox
            name="loop"
            label="Loop"
            isSelected={progressionSettings.loop}
            onChange={formInputOnChangeHandler('loop')}
          />
          <InlineSpace size={25} />
          <NumberRange
            name="transposeProgression"
            label="Transpose"
            value={progressionSettings.transpose}
            range={[-11, 11]}
            onChange={transposeHandler}
          />
          <InlineSpace size={25} />
          {/* <NumberRange */}
          {/*  name="octave" */}
          {/*  label="Octave" */}
          {/*  value={progressionSettings.octave} */}
          {/*  range={[0, 7]} */}
          {/*  hideButtons={true} */}
          {/*  onChange={octaveChangeHandler} */}
          {/* /> */}
          <label className={styles.label} htmlFor="octave">
            <span className={styles.label}>Octave</span>
            <input
              className={classNamesHelper([
                styles.input,
                styles['input-small'],
              ])}
              type="number"
              id="octave"
              name="octave"
              placeholder="3"
              min="0"
              max="7"
              value={progressionSettings.octave}
              onChange={formInputOnChangeHandler('octave')}
            />
          </label>
          <InlineSpace size={25} />
          <label className={styles.label} htmlFor="time">
            <span className={styles.label}>Time</span>
            <input
              className={classNamesHelper([
                styles.input,
                styles['input-small'],
              ])}
              type="number"
              id="time"
              name="time"
              placeholder="4"
              min="1"
              max="64"
              value={progressionSettings.time}
              onChange={formInputOnChangeHandler('time')}
            />
          </label>
          <InlineSpace size={25} />
          <div className={styles.bpmContainer}>
            <BPMInput
              name="bpm"
              value={progressionSettings.bpm}
              label="BPM"
              onChange={onBPMChange}
            />
          </div>
          <InlineSpace size={25} />
          <label className={styles.label} htmlFor="instrument">
            <span className={styles.label}>Instrument</span>
            <select
              name="instrument"
              id="instrument"
              className={styles.select}
              value={progressionSettings.instrument}
              onChange={instrumentChangeHandler}
            >
              <option value="web-audio">Web Audio</option>
              <option value="piano">Piano</option>
              <option value="guitar-acoustic">Guitar</option>
              <option value="guitar-electric">Electric Guitar</option>
              <option value="cello">Cello</option>
              <option value="violin">Violin</option>
              <option value="xylophone">XyloPhone</option>
            </select>
          </label>
          <InlineSpace size={25} />
          <label className={styles.label} htmlFor="arpeggiateStyle">
            <span className={styles.label} title="Arpeggio Style">
              Arpeggio
            </span>
            <select
              className={styles.select}
              name="arpeggiateStyle"
              id="arpeggiateStyle"
              value={progressionSettings.arpeggiateStyle}
              onChange={formInputOnChangeHandler('arpeggiateStyle')}
            >
              <option value="">No Arpeggio</option>
              <option value="arpeggiate1">Arpeggio 1</option>
              <option value="default">Default</option>
              <option value="default-reverse">Reverse</option>
              <option value="build">Include Octave</option>
              <option value="alberti1">Alberti 1</option>
              <option value="alberti2">Alberti 2</option>
              <option value="alberti3">Alberti 3</option>
              <option value="ballad">Ballad</option>
            </select>
          </label>
        </div>
        <BlockSpace size={100} />
        <Collapsible title="Advanced Settings">
          <BlockSpace size={100} />
          <div className={styles['form-section']}>
            <label className={styles.label} htmlFor="velocity">
              <span className={styles.label}>Velocity</span>
              <select
                className={styles.select}
                name="velocity"
                id="velocity"
                value={progressionSettings.velocity}
                onChange={formInputOnChangeHandler('velocity')}
              >
                <option value="verysoft">Very Soft</option>
                <option value="soft">Soft</option>
                <option value="medium">Medium</option>
                <option value="mediumhigh">Mid High</option>
                <option value="full">Full</option>
              </select>
            </label>
            <InlineSpace size={25} />
            <label className={styles.label} htmlFor="ADSR">
              <span>
                ADSR
                <sub className={styles.beta}>Web Audio</sub>
              </span>
              <input
                className={classNamesHelper([
                  styles.input,
                  styles['settings-input'],
                ])}
                type="text"
                name="ADSR"
                id="ADSR"
                disabled={progressionSettings.instrument !== 'web-audio'}
                placeholder="0.1, 0.01, 0.1, 0.1"
                value={progressionSettings.ADSR || ''}
                onChange={e => {
                  ADSR.onChange(e);
                  dispatch(
                    setSyncChanges({
                      ADSR: e.target.value,
                    }),
                  );
                }}
              />
            </label>
            <InlineSpace size={25} />
            <CustomCheckBox
              name="IADSR"
              label="IADSR"
              isSelected={progressionSettings.IADSR}
              onChange={formInputOnChangeHandler('IADSR')}
            />
            <InlineSpace size={25} />
            <label className={styles.label} htmlFor="timeSignature">
              <span className={styles.label}>Time Sig</span>
              <input
                className={classNamesHelper([
                  styles.input,
                  styles['input-small'],
                ])}
                type="text"
                id="timeSignature"
                name="timeSignature"
                placeholder="4/4"
                value={progressionSettings.timeSignature}
                onChange={e => {
                  dispatch(
                    setSyncChanges({
                      ...progressionSettings,
                      timeSignature: e.target.value,
                    }),
                  );
                }}
              />
            </label>
            <InlineSpace size={25} />
            <label className={styles.label} htmlFor="rhythm">
              <span>
                Rhythm<sub className={styles.beta}>Beta</sub>
              </span>
              <input
                className={classNamesHelper([
                  styles.input,
                  styles['settings-input'],
                ])}
                type="text"
                name="rhythm"
                id="rhythm"
                placeholder="4,4,4,4"
                value={progressionSettings.rhythm || ''}
                onChange={e => {
                  // rhythm.onChange(e);
                  // const parsedRhythm = parseRhythm(e.target.value);
                  dispatch(
                    setSyncChanges({
                      ...progressionSettings,
                      rhythm: e.target.value,
                    }),
                  );
                }}
              />
            </label>
            <InlineSpace size={25} />
            <label className={styles.label} htmlFor="customArpeggio">
              <span>
                Custom Arpeggio<sub className={styles.beta}>Beta</sub>
              </span>
              <input
                className={classNamesHelper([
                  styles.input,
                  styles['settings-input'],
                ])}
                type="text"
                name="customArpeggio"
                id="customArpeggio"
                placeholder="1, 5, 8, 10"
                value={progressionSettings.customArpeggio}
                onChange={formInputOnChangeHandler('customArpeggio')}
              />
            </label>
          </div>
        </Collapsible>
      </form>
    </div>
  );
}
